import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Section, SectionHeader } from "@uaveiro/ui";
import { StoreContext } from "../../context/StoreContext";
import { withTheme } from "styled-components";
import { lang } from "../../translations";
import { FormattedMessage, injectIntl } from "react-intl";

const TimelyTemplate2 = ({ theme, title, background }) => {
  const { state } = useContext(StoreContext);

  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("id", "timely_script");
    script.setAttribute("class", "timely_script");
    script.setAttribute("src", "https://events.timely.fun/embed.js");
    script.setAttribute("data-max-height", "0");

    let url = "https://events.timely.fun/2p89s104/";
    
    if (lang() !== "pt") {
      url += "?lang=en-UK"; 
    }

    script.setAttribute("data-src", url);

    const container = document.getElementById("timely-container");
    container.appendChild(script);

    return () => {
      container.removeChild(script);
    };
  }, []);

  return (
    <Section
      backgroundColor={
        theme.highContrast.backgroundColor || background || state.activeColor
      }
    >
      <SectionHeader textColor={theme.components.agenda.titleColor}>
        <FormattedMessage
          id="app.organism.agenda.title"
          defaultMessage="Agenda"
        />
      </SectionHeader>
      <div className="container">
        <div id="timely-container"></div>
      </div>
    </Section>
  );
};


export default withRouter(withTheme(injectIntl(TimelyTemplate2)));
